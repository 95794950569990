import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.scss';
import App from './App';

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_IO_ENV,
  dsn: process.env.REACT_APP_SENTRY_IO_PROJECT
});

ReactDOM.render(<App />, document.getElementById('root'));
