import React, { useState, useEffect } from "react";
import QRCode from "qrcode";

import "./TimeCode.scss";

import { firestore } from "../../firebase";

const BASE_URL = process.env.REACT_APP_CHECK_IN_BASE_URL;

const TimeCode = ({ id }) => {
  const [timeCode, setTimeCode] = useState("");

  useEffect(() => {
    const unsubscribe = firestore
      .collection("studioCodes")
      .doc(id)
      .onSnapshot((snap) => {
        if (snap) {
          setTimeCode(snap.data().code);
        }
      });

    return () => {
      unsubscribe();
    };
  }, [id]);

  useEffect(() => {
    async function generateQRCode() {
      if (timeCode) {
        await QRCode.toCanvas(
          document.getElementById("qrcode"),
          `${BASE_URL}?code=${timeCode}`,
          { errorCorrectionLevel: "L" }
        );
      }
    }

    generateQRCode();
  }, [timeCode]);

  return (
    <div className="time-code-wrapper">
      <canvas id="qrcode"></canvas>
    </div>
  );
};

export default TimeCode;
