import React, { useState, useEffect } from "react";
import { groupBy } from "lodash";
import { getDay } from "date-fns";

import "./CoursePlan.scss";

const germanOffset = 120; // Summer Time
//const germanOffset = 60; // Winter Time

console.log(germanOffset);

const Weekday = ({ weekday }) => (
  <div className="weekday-wrapper">
    <p>{weekday}</p>
  </div>
);

const Course = ({ starts, ends, name, trainer, style, active }) => {
  return (
    <div className={`course-wrapper ${active ? "active" : ""}`} style={style}>
      <p className="course-time">
        {starts} - {ends}
      </p>
      <p className="course-name">{name}</p>
      <p className="course-trainer">{trainer}</p>
    </div>
  );
};

const CoursePlan = ({ courses }) => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNow(Date.now());
    }, 1000 * 60);

    return () => clearTimeout(timeout);
  }, [now]);

  const weekdays = [
    "MONTAG",
    "DIENSTAG",
    "MITTWOCH",
    "DONNERSTAG",
    "FREITAG",
    "SAMSTAG",
    "SONNTAG",
  ];
  const groupedCourses = groupBy(courses, "weekday");

  let minRows = 7;

  for (let i = 0; i < weekdays.length; i++) {
    // Make sure every weekday exists in object as key
    if (!groupedCourses.hasOwnProperty(i)) {
      groupedCourses[i] = [];
    } else {
      // Check if minRows is enough
      if (groupedCourses[i].length > minRows) {
        minRows = groupedCourses[i].length;
      }
    }
  }

  Object.keys(groupedCourses).forEach((key) => {
    // Fill course plan with empty courses
    while (groupedCourses[key].length < minRows) {
      groupedCourses[key].push({
        placeholder: true,
      });
    }
  });

  let fontSize = "1rem";

  if (minRows > 7) {
    fontSize = "0.75rem";
  }

  const newsWrapper = window.document.getElementsByClassName("news-wrapper")[0];
  const newsHeader = window.document.getElementsByClassName("news-header")[0];

  if (minRows > 10) {
    fontSize = "0.2rem";

    if (newsWrapper && newsHeader) {
      newsWrapper.classList.add("hidden");
    }
  } else {
    if (newsWrapper && newsHeader) {
      newsWrapper.classList.remove("hidden");
    }
  }

  console.log(fontSize);

  const gridTemplateRows = {
    gridTemplateRows: `9% repeat(${minRows}, 1fr)`,
    fontSize,
  };

  const weekdayElements = weekdays.map((weekday, index) => (
    <Weekday weekday={weekday} key={index} />
  ));

  const courseElements = Object.keys(groupedCourses).map((key, weekdayIndex) =>
    groupedCourses[key].map((course, courseIndex) => {
      const style = {
        gridColumn: weekdayIndex || 7,
        gridRow: courseIndex + 2,
      };

      let active = false;

      // Return empty course div if not a real course
      if (course.placeholder) {
        return <div className="course-wrapper" key={key + courseIndex} />;
      }

      if (course.weekday === getDay(now)) {
        let offset = new Date().getTimezoneOffset();
        let starts = new Date();
        let ends = new Date();

        const [startsHours, startsMinutes] = course.starts.split(":");
        const [endsHours, endsMinutes] = course.ends.split(":");

        starts.setHours(parseInt(startsHours) - (germanOffset + offset) / 60);
        starts.setMinutes(parseInt(startsMinutes));
        ends.setHours(parseInt(endsHours) - (germanOffset + offset) / 60);
        ends.setMinutes(parseInt(endsMinutes));

        if (now >= starts.getTime() && now <= ends.getTime()) {
          active = true;
        }
      }

      return (
        <Course {...course} key={course.id} style={style} active={active} />
      );
    })
  );

  return (
    <div className="course-plan-wrapper" style={gridTemplateRows}>
      {weekdayElements}
      {courseElements}
    </div>
  );
};

export default CoursePlan;
