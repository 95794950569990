import React from "react";

import "./News.scss";

const News = ({ news }) => (
  <div className="news-wrapper">
    {/* eslint-disable-next-line */}
    <marquee truespeed="truespeed" scrolldelay="40">
      {news}
    </marquee>
  </div>
);

export default News;
