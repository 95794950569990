import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { firestore } from "./firebase";

import "./App.scss";

import CoursePlan from "./components/course-plan/CoursePlan";
import News from "./components/news/News";
import TimeCode from "./components/time-code/TimeCode";
import NewsHeader from "./components/news/NewsHeader";

const App = ({ match }) => {
  const [courses, setCourses] = useState([]);
  const [news, setNews] = useState("");
  const courseRoomId = new URLSearchParams(window.location.search).get(
    "courseRoomId"
  );

  useEffect(() => {
    const unsubscribe = courseRoomId
      ? firestore
          .collection("courses")
          .orderBy("weekday")
          .orderBy("starts")
          .where("studio", "==", match.params.id)
          .where("courseRoom", "==", courseRoomId)
          .onSnapshot((snap) => {
            setCourses(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
          })
      : firestore
          .collection("courses")
          .orderBy("weekday")
          .orderBy("starts")
          .where("studio", "==", match.params.id)
          .onSnapshot((snap) => {
            setCourses(snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
          });

    return () => {
      unsubscribe();
    };
  }, [match.params.id, courseRoomId]);

  useEffect(() => {
    const unsubscribe = firestore
      .collection("broadcasts")
      .where("studios", "array-contains", match.params.id)
      .onSnapshot((snap) => {
        const broadcasts = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (broadcasts.length) {
          setNews(
            broadcasts.reduce(
              (prev, curr) => (prev += curr.text + " +++ "),
              "+++ "
            )
          );
        } else {
          setNews("");
        }
      });

    return () => {
      unsubscribe();
    };
  }, [match.params.id]);

  return (
    <div className="app-wrapper">
      <CoursePlan courses={courses} />
      <TimeCode id={match.params.id} />
      <NewsHeader />
      <News news={news} />
    </div>
  );
};

const Wrapper = () => (
  <Router>
    <Route path="/:id" component={App} />
  </Router>
);

export default Wrapper;
